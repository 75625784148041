import "./App.css";
import { useEffect, useState } from "react";
import { ZoomMtg } from "@zoomus/websdk";
import axios from "axios";
ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function App() {
  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  var apiKey = "cwmlnQQfT22PfOzpIt1bgw";
  var apiSecret = "CMc2Vxr7IwDKIDolkTj7D4aCDXBkEj28Q358";
  var meetingNumber = "";
  var role = "";
  var leaveUrl = "https://quranteacherlive.com";
  var userName = "";
  var userEmail = "";
  var passWord = "";
  const BaseUrl = "https://admin.quranteacherlive.com";
  const [paramsFetched, setParamsFetched] = useState(false);

  //Parse Query to get params
  function parseQuery() {
    return (function () {
      var href = window.location.href;
      var queryString = href.substr(href.indexOf("?"));
      var query = {};
      var pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
      ).split("&");
      for (var i = 0; i < pairs.length; i += 1) {
        var pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
      }
      return query;
    })();
  }

  //decode the encoded Url params
  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  useEffect(async () => {
    const QueryParam = parseQuery();
    meetingNumber = QueryParam.mn;
    userName = b64DecodeUnicode(QueryParam.name);
    passWord = QueryParam.pwd;
    role = parseInt(QueryParam.role, 10);
    userEmail = b64DecodeUnicode(QueryParam.email);
    await setParamsFetched(true);

    console.log(meetingNumber, userEmail, userName, passWord, role);
    getSignature();
  });

  function getSignature() {
    console.log({
      meetingNumber: meetingNumber,
      apiKey: apiKey,
      apiSecret: apiSecret,
      role: role,
    });
    ZoomMtg.generateSignature({
      meetingNumber: meetingNumber,
      apiKey: apiKey,
      apiSecret: apiSecret,
      role: 1,
      success: function (res) {
        console.log("signature", res);
        startMeeting(res.result);
      },
    });
  }

  function startMeeting(signature) {
    console.log({
      signature: signature,
      meetingNumber: meetingNumber,
      userName: userName,
      apiKey: apiKey,
      userEmail: userEmail,
      passWord: passWord,
    });
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl:
        leaveUrl +
        `?role=${role}&meetingNumber=${meetingNumber}&leaveTime=${GetFormattedDateAndTime(
          new Date()
        )}&meeting_query=${true}`,
      isSupportAV: true,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            //metting joined start
            var ParamData = {};
            if (role === 1) {
              //tutor join the metting
              ParamData = {
                instructor_join: 1,
                meeting_id: meetingNumber,
                instructor_join_time: GetFormattedDateAndTime(new Date()),
              };
              console.log("instructor joined metting", ParamData);
              MettingStatusApi(ParamData);
            }
            if (role === 0) {
              //student join the metting
              ParamData = {
                student_join: 1,
                meeting_id: meetingNumber,
                student_join_time: GetFormattedDateAndTime(new Date()),
              };
              MettingStatusApi(ParamData);
              console.log("student joined metting", ParamData);
            }
            //metting joined end
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  const GetFormattedDateAndTime = (date) => {
    var todayTime = new Date(date);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    var Time =
      todayTime.getHours() +
      ":" +
      todayTime.getMinutes() +
      ":" +
      todayTime.getSeconds();

    var newMonth = month < 10 ? `0${month}` : month;
    var newDay = day < 10 ? `0${day}` : day;
    return year + "-" + newMonth + "-" + newDay + " " + Time;
  };

  //mettingJoinde
  const MettingStatusApi = (params) => {
    axios
      .post(BaseUrl + "/api/zoom/update/meeting/status", params)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return <div className="App"></div>;
}

export default App;
